/********** Add Your Global CSS Here **********/

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
    /*margin-bottom: 1em;*/
    font-family: 'Prompt', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

.scrollbar-track-y,
.scrollbar-thumb-y {
    width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
    height: 5px !important;
}

.scrollbar-thumb {
    border-radius: 0 !important;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow:after,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow:after,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow:after,
.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow:after,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow:after,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow:after {
    left: 0;
    margin-left: -4px;
}


/********** Add Your Global RTL CSS Here **********/


/* Popover */

html[dir='rtl'] .ant-popover {
    text-align: right;
}


/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
    text-align: right;
}


/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
    left: 0;
    right: auto;
}

html[dir='rtl'] .ant-modal-close {
    right: inherit;
    left: 0;
}

html[dir='rtl'] .ant-modal-footer {
    text-align: left;
}

html[dir='rtl'] .ant-modal-footer button+button {
    margin-left: 0;
    margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
    margin-right: 42px;
}

html[dir='rtl'] .ant-btn>.anticon+span,
html[dir='rtl'] .ant-btn>span+.anticon {
    margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
    margin-left: 0;
    margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
    padding-left: 25px;
    padding-right: 29px;
}

html[dir='rtl'] .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) .anticon {
    margin-right: -14px;
    margin-left: 0;
}


/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body>.anticon {
    margin-left: 16px;
    margin-right: 0;
    float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
    float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button+button {
    margin-right: 10px;
    margin-left: 0;
}


/* Message */

html[dir='rtl'] .ant-message .anticon {
    margin-left: 8px;
    margin-right: 0;
}


/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
    padding-right: 20px;
    padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
    text-align: left;
}


/* Notification */

html[dir='rtl'] .ant-notification-notice-closable .ant-notification-notice-message {
    padding-left: 24px;
    padding-right: 0;
}

html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-message,
html[dir='rtl'] .ant-notification-notice-with-icon .ant-notification-notice-description {
    margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
    right: auto;
    left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
    left: 0;
}


/* Dropzone */

html[dir='rtl'] .dz-hidden-input {
    display: none;
}

.glass {
    /* background styles */
    position: relative;
    display: inline-block;
    /* text styles */
    border-radius: 10px;
    box-shadow: 0px 1px 4px -2px #333;
}

.glass:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100%);
    border-radius: 10px;
    height: 50%;
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
}

.glassRed {
    /* background styles */
    position: relative;
    display: inline-block;
    /* text styles */
    border-radius: 10px;
    box-shadow: 0px 1px 4px -2px #333;
}

.glassRed:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: calc(100%);
    border-radius: 0px;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    height: 50%;
    background: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.1));
}

.pulse {
    background-color: #CC0000;
    border: 6px solid #FFDC69;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
    margin: 20px;
}

.bg-pulse {
    background-color: #fff;
    border-radius: 50%;
}

.circle__spin {
    position: absolute;
    width: 260px;
    height: 260px;
}

.circle__spin svg {
    width: 100%;
    height: 100%;
}

.circle__spin circle {
    stroke-width: 20;
    /* control the dashes width */
    stroke-dasharray: 20, 20;
    /* control the dashes spacing */
    fill: none;
    stroke: #CC0000;
}

.circle__spin svg {
    animation: spin 42s linear infinite;
}

@keyframes spin {
    100% {
        transform: rotateZ(360deg);
    }
}

.ant-switch-checked {
    background-color: #f5f2e4;
}